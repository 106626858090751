const plugin = require('tailwindcss/plugin')
const colors = require('tailwindcss/colors')
const defaultTheme = require('tailwindcss/defaultTheme')

module.exports = {
  mode: 'jit',
  purge: ['./pages/**/*.tsx', './components/**/*.tsx'],
  darkMode: false,
  important: true,
  theme: {
    extend: {
      backgroundImage: theme => ({
        'on-your-team': "url('/images/product-pages/Why-XL-2.png')"
      }),
      lineHeight: {
        normal: '125%'
      },
      colors: {
        brand: '#009FD9',
        gold: {
          50: '#f9f5e5',
          100: '#faf0bd',
          200: '#f6e67c',
          300: '#f1d439',
          400: '#ffbf00',
          500: '#e19706',
          600: '#cf7404',
          700: '#ae5708',
          800: '#8e440e',
          900: '#743710'
        },
        orange: colors.orange,
        blue: {
          100: '#EAF6FA',
          200: '#B2EBFF',
          300: '#79D2F2',
          400: '#009FD9',
          500: '#007FAD',
          600: '#005979'
        },
        black: {
          300: '#676D73',
          900: '#2F3033'
        },
        gray: {
          400: '#D3D4D5',
          300: '#E9ECED',
          200: '#FAFAFA'
        },
        indigo: {
          100: '#E8F1FD',
          200: '#CCE1FF',
          300: '#96C2FF',
          400: '#5968E2',
          500: '#4F54B2',
          600: '#383C80'
        },
        purple: {
          100: '#F5EFFF',
          200: '#DFCCFF',
          300: '#C9ACFD',
          400: '#8D56EB',
          500: '#6637B6',
          600: '#492782'
        },
        green: {
          100: '#E1FDF3',
          200: '#C6F7DA',
          300: '#73E4A2',
          400: '#2DB783',
          500: '#16855B',
          600: '#054E33'
        },
        yellow: {
          100: '#FDF7E7',
          200: '#FFEBB3',
          300: '#FFDD80',
          400: '#FEBE14',
          500: '#A77005',
          600: '#714601'
        },
        red: {
          100: '#FFEFF0',
          200: '#FFD9D9',
          300: '#FFB0B0',
          400: '#FF5A5F',
          500: '#B22D31',
          600: '#7D0D10'
        }
      },
      fontFamily: {
        ...defaultTheme.fontFamily,
        heading: ['Mark', ...defaultTheme.fontFamily.sans],
        sans: ['Mark', ...defaultTheme.fontFamily.sans],
        mono: ['Inconsolata', ...defaultTheme.fontFamily.mono],
        thumbtack: ['Mark', ...defaultTheme.fontFamily.sans]
      },
      fontSize: {
        xxs: 10,
        22: ['22px', '30px'],
        28: ['28px', '36px']
      },
      zIndex: {
        '-10': '-10'
      },
      screens: {
        print: { raw: 'print' }
      },
      typography: {
        DEFAULT: {
          css: {
            '& > .wp-block-image > * > a': {
              display: 'flex',
              justifyContent: 'center'
            },
            '& > center': {
              margin: '2em 0'
            }
          }
        }
      },
      gridTemplateColumns: {
        14: 'repeat(14, minmax(0, 1fr))',
        16: 'repeat(16, minmax(0, 1fr))',
        18: 'repeat(18, minmax(0, 1fr))'
      },
      gridColumn: {
        'span-14': 'span 14 / span 14',
        'span-16': 'span 16 / span 16'
      }
    }
  },
  variants: {
    extend: {
      borderRadius: ['first', 'last'],
      backgroundColor: ['even', 'disabled'],
      textColor: ['disabled', 'active'],
      opacity: ['disabled'],
      cursor: ['disabled'],
      transform: ['hover']
    }
  },
  plugins: [
    require('@tailwindcss/typography'),
    plugin(({ addBase, config }) => {
      addBase({
        h1: { fontFamily: config('theme.fontFamily.heading').join(', ') },
        h2: { fontFamily: config('theme.fontFamily.heading').join(', ') },
        h3: { fontFamily: config('theme.fontFamily.heading').join(', ') },
        h4: { fontFamily: config('theme.fontFamily.heading').join(', ') },
        h5: { fontFamily: config('theme.fontFamily.heading').join(', ') },
        h6: { fontFamily: config('theme.fontFamily.heading').join(', ') }
      })
    }),
    plugin(({ addUtilities }) => {
      addUtilities({
        '.folded-l': {
          'clip-path': 'polygon(10% 0,100% 0,100% 100%,0 100%,0 30%)'
        },
        '.folded-l-15': {
          'clip-path': 'polygon(10% 0,100% 0,100% 100%,0 100%,0 15%)'
        },

        '.folded-r': {
          'clip-path': 'polygon(77% 0, 100% 30%, 100% 100%, 0 100%, 0 0)'
        }
      })
    })
  ]
}
