import { DefaultSeo } from 'next-seo'
import * as Sentry from '@sentry/node'
import { Hydrate } from 'react-query/hydration'
import { SkeletonTheme } from 'react-loading-skeleton'
import { QueryClient, QueryClientProvider } from 'react-query'

import { theme } from '../tailwind.config'
import { Notifications } from '@components/Notifications'
import { UserContextProvider } from '@hooks/user/context'

// Root Tailwind CSS styles with resets
import 'tailwindcss/tailwind.css'

// Reach UI base styles
import '@reach/dialog/styles.css'
import '@reach/listbox/styles.css'
import '@reach/tooltip/styles.css'
import '@reach/combobox/styles.css'

import 'styles/global.css'
import 'styles/blog.css'
import 'styles/thumbtack.css'
import Script from 'next/script'

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN
  })
}

export const isProduction = process.env.NODE_ENV === 'production'

const queryClient = new QueryClient()

function App({ Component, pageProps, err }) {
  return (
    <UserContextProvider>
      <SkeletonTheme
        color={theme.extend.colors.gray[200]}
        highlightColor={theme.extend.colors.gray[300]}
      >
        <Notifications />
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <DefaultSeo
              titleTemplate="%s | Thumbtack Home Repair Estimates"
              title="Get Repair Estimates"
              description="Estimate home inspection and maintenance repair costs. Upload any inspection report. Get accurate local pricing for all repair items in 24 hours."
              canonical="https://repair.thumbtack.com/"
              openGraph={{
                type: 'website',
                url: 'https://repair.thumbtack.com/',
                title: 'Thumbtack Home Repair Estimates',
                description:
                  'Estimate home inspection and maintenance repair costs. Upload any inspection report. Get accurate local pricing for all repair items in 24 hours.',
                images: [
                  {
                    url: '/images/thumbtack/wordmark_dark.png',
                    width: 2401,
                    height: 343,
                    alt: 'Thumbtack Logo'
                  }
                ]
              }}
              // twitter={{
              //   site: '@majordomohq',
              //   cardType: 'summary_large_image'
              // }}
            />

            {/* <!-- Google tag (gtag.js) --> */}
            {process.env.NODE_ENV === 'production' && (
              <>
                <Script
                  strategy="afterInteractive"
                  src="https://www.googletagmanager.com/gtag/js?id=G-NQV9Q9G9BG"
                />
                <Script id="google-analytics">
                  {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
      
                gtag('config', 'G-NQV9Q9G9BG');
              `}
                </Script>
              </>
            )}

            <Component {...pageProps} err={err} />
          </Hydrate>
        </QueryClientProvider>
      </SkeletonTheme>
    </UserContextProvider>
  )
}

export default App
