import { useState, createContext, useCallback, PropsWithChildren } from 'react'

interface UserContextValues {
  hasToken: boolean
  setToken: (token: string) => void
  removeToken: () => void
}

export const UserContext = createContext<UserContextValues>({
  hasToken: Boolean(
    typeof window !== 'undefined' && localStorage.getItem('majordomo-token')
  ),
  setToken: (token: string) => {
    localStorage.setItem('majordomo-token', token)
  },
  removeToken: () => {
    localStorage.removeItem('majordomo-token')
  }
})

export function UserContextProvider(props: PropsWithChildren<{}>) {
  const [hasToken, setHasToken] = useState(
    Boolean(
      typeof window !== 'undefined' && localStorage.getItem('majordomo-token')
    )
  )

  const setToken = useCallback((token: string) => {
    localStorage.setItem('majordomo-token', token)
    setHasToken(true)
  }, [])

  const removeToken = useCallback(() => {
    localStorage.removeItem('majordomo-token')
    setHasToken(false)
  }, [])

  return (
    <UserContext.Provider
      value={{
        hasToken,
        setToken,
        removeToken
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}
